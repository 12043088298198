import React from "react"
import Layout from "./layout"
import BlockContent from "@sanity/block-content-to-react"
import "./sticker.scss"
import StickerCard from "./stickerCard"
import { Helmet } from "react-helmet"

const Sticker = data => {
  const s_data = data.pageContext
  const metaData = s_data.metaStuff
  console.log(metaData)
  return (
    <Layout>
      <Helmet>
        {metaData !== null && metaData.locale !== null && (
          <meta property="og:locale" content={metaData.locale} />
        )}
        {metaData !== null && metaData.type !== null && (
          <meta property="og:type" content={metaData.type} />
        )}
        {metaData !== null && metaData.title !== null && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData !== null && metaData.description !== null && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData !== null && metaData.url !== null && (
          <meta property="og:url" content={metaData.url} />
        )}
        {metaData !== null && metaData.image !== null && (
          <meta property="og:image" content={metaData.image} />
        )}

        {metaData !== null && metaData.card !== null && (
          <meta name="twitter:card" content={metaData.card} />
        )}
        {metaData !== null && metaData.title !== null && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData !== null && metaData.description !== null && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData !== null && metaData.image !== null && (
          <meta name="twitter:image" content={metaData.image} />
        )}
        {metaData !== null && metaData.url !== null && (
          <meta name="twitter:url" content={metaData.url} />
        )}
      </Helmet>
      <div className="s_wrap">
        <div className="prod full-bleed layouted">
          <div className="display">
            <img src={s_data.images[0].asset.url} alt="img" />
          </div>
          <div className="content">
            <h1>{s_data.title}</h1>
            <div className="tags">
              {s_data.tags.map(tag => (
                <div className="tag">{tag}</div>
              ))}
            </div>
            <BlockContent blocks={s_data._rawBody.en}></BlockContent>
            <h2 className="price">$ {s_data.price}</h2>
            <br />
            <a target="_blank" href={s_data.ctaLink} className="big_cta">
              Buy Now
            </a>
          </div>
        </div>
        <div className="rels full-bleed layouted">
          <hr />
          <h1>Related Stickers</h1>
          <div className="rel_p">
            {s_data.relStick.map(rp => (
              <StickerCard data={rp} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sticker
