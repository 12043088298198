import { Link } from "gatsby"
import React from "react"
import "./stickerCard.scss"

const StickerCard = ({ data }) => {
  return (
    <div className="c_wrap">
      <Link to={`/${data.slug.current}`}>
        <div className="display">
          <img src={data.images[0].asset.url} alt="img" />
        </div>
      </Link>
      <div className="content">
        <h2>{data.title}</h2>
        <h3 className="price">$ {data.price}</h3>
        <div className="tags">
          {data.tags.map(tag => (
            <div className="tag">{tag}</div>
          ))}
        </div>
        <br />
        <Link to={`/${data.slug.current}`} className="buy_cta">
          Buy Now
        </Link>
      </div>
    </div>
  )
}

export default StickerCard
